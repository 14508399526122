/**
 * 业务服务（方法）统一返回结果
 * 用于界面通知提醒
 *
 * @author zhoujianhui
 * @date 10/25/2021
 * @lastModifiedBy zhoujianhui
 * @lastModifiedDate 02/22/2022
 */
class Result {
    type // 结果类型
    msg  // 结果消息
    data // 结果数据

    constructor(type, msg, data) {
        this.type = type;
        this.msg = msg;
        this.data = data;
    }

    isSuccess() {
        return Result.SUCCESS === this.type;
    }

    isError() {
        return Result.ERROR === this.type;
    }

    //////

    // 支持的结果类型
    static SUCCESS = "success"
    static WARNING = "warning"
    static ERROR = "error"
    static INFO = "info"

    static success(msg) {
        return this.builder().type(Result.SUCCESS).msg(msg)
    }

    static warning(msg) {
        return this.builder().type(Result.WARNING).msg(msg)
    }

    static error(msg) {
        return this.builder().type(Result.ERROR).msg(msg)
    }

    static info(msg) {
        return this.builder().type(Result.INFO).msg(msg)
    }

    static builder() {
        return {
            type(type) {
                this.typeParam = type
                return this
            },
            msg(msg) {
                this.msgParam = msg
                return this
            },
            data(data) {
                this.dataParam = data
                return this
            },
            build() {
                return new Result(this.typeParam, this.msgParam, this.dataParam)
            }
        }
    }
}

export default Result